// import logo from './logo.svg';
import './App.scss';
import Main from './app/main/Main';
import About from './app/about/About';
import Contacts from './app/contacts/Contacts';
import Clients from './app/clients/Clients';
import Jobs from './app/jobs/Jobs';
import React from 'react';

var scrollPositionY = 0;
var setScrollPositionY = (value) => {scrollPositionY = value;}

function App() {
    let scrollLocked = false;

    let executeScroll = (index) => {
        let element = document.getElementsByClassName("app")[0].children[index];
        element.scrollIntoView();
        setScrollPositionY(element.getBoundingClientRect().y);
    }

    window.onresize = () => {
        if (window.innerHeight >= 500 && window.innerWidth >= 1000) {
            setScrollPositionY(0);
            window.scrollTo(0,0);
        }
    };

    setTimeout(() => setScrollPositionY(window.scrollY), 200); // at start window.scrollY = 0
    
    return (
        <div className="app" key="app"
            onWheel={(deltaMode) => {
                if (window.innerHeight >= 500 && window.innerWidth >= 1000) {
                    // deltaMode.preventDefault();
                    if (deltaMode.deltaY > 0) { // down
                        if (!scrollLocked && scrollPositionY + window.innerHeight < document.body.clientHeight) {
                            setScrollPositionY(scrollPositionY + window.innerHeight);
                            window.scrollTo(0, scrollPositionY);
                            scrollLocked = true;
                            setTimeout(() => {scrollLocked = false;}, 500);
                        }
                    } else {
                        if (!scrollLocked && scrollPositionY > 0) {
                            let newPosition = scrollPositionY - window.innerHeight;
                            setScrollPositionY(newPosition);
                            if (scrollPositionY < 0) setScrollPositionY(0);
                            window.scrollTo(0, scrollPositionY);
                            scrollLocked = true;
                            setTimeout(() => {scrollLocked = false;}, 500);
                        }
                    }
                }
            }}
        >
            <Main setScrollPositionY={setScrollPositionY}  key="main" executeScroll={executeScroll}/>
            <About key="about"/>
            <Jobs key="jobs"/>
            <Clients key="clients"/>
            <Contacts key="contacts"/>
        </div>
    );
}

export default App;
