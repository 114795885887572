import React from 'react';
import BasePage from '../../utils/BasePage';
import "./Contacts.scss";


class Contacts extends React.Component {

    render () {
        return (
        <BasePage className="contacts">
            <div className="body">
                <div onClick={(event) => {
                        if (event.target.className === "zoomed") {
                            event.target.className = "";
                            event.target.parentElement.className = "body";
                        }
                    }}>
                    <img src="/certificate.jpg" alt="" onClick={(event) => {
                        if (event.target.parentElement.className === "zoomed") {
                            event.target.parentElement.className = "";
                            event.target.parentElement.parentElement.className = "body";
                        } else {
                            event.target.parentElement.className = "zoomed";
                            event.target.parentElement.parentElement.className = "body zoomed first";
                        }
                    }}/>
                </div>
                <div className="crutch-plug first"/>
                <div onClick={(event) => {
                        if (event.target.className === "zoomed") {
                            event.target.className = "";
                            event.target.parentElement.className = "body";
                        }
                    }}>
                    <img src="/insurance.jpg" style={{border: "1px solid rgb(0,0,0, 0.14)"}}  alt="" onClick={(event) => {
                        if (event.target.parentElement.className === "zoomed") {
                            event.target.parentElement.className = "";
                            event.target.parentElement.parentElement.className = "body";
                        } else {
                            event.target.parentElement.className = "zoomed";
                            event.target.parentElement.parentElement.className = "body zoomed central";
                        }
                    }}/>
                </div>
                <div className="crutch-plug second"/>
                <div onClick={(event) => {
                        if (event.target.className === "zoomed") {
                            event.target.className = "";
                            event.target.parentElement.className = "body";
                        }
                    }}>
                    <img src="/certificate2.jpeg" alt="" onClick={(event) => {
                        if (event.target.parentElement.className === "zoomed") {
                            event.target.parentElement.className = "";
                            event.target.parentElement.parentElement.className = "body";
                        } else {
                            event.target.parentElement.className = "zoomed";
                            event.target.parentElement.parentElement.className = "body zoomed second";
                        }
                    }}/>
                </div>
            </div>
            <div className="footer">
                <div className="left">
                    {`© 2004 - ${new Date().getFullYear()}\nООО “Оценочное агентство”`}
                </div>
                <div className="right">
                    <div>Телефон: +7 (495) 766-04-56</div>
                    <div>WhatsApp, Telegram, Signal: +7 (929) 503-04-07</div>
                    <div>Email: oa77msk@gmail.com</div>
                    <div>Telegram: OA77msk</div>
                    <div>ОГРН 1045011454256, присвоен 08.06.2004, ИНН: 5074027310, КПП: 771501001</div>
                </div>
            </div>
        </BasePage>
        );
    }
}

export default Contacts;