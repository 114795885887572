import React from 'react';
import BasePage from '../../utils/BasePage';
import './Jobs.scss';


class Jobs extends React.Component {
    render () {
        return (
        <BasePage className="jobs">
            <div style={{textAlign: "center"}} className="header">
                <h1>Наши услуги</h1>
            </div>
            <div className="body">
                <div className="valuation">
                    <h1>Независимая оценка</h1>
                    <ul>
                        <li>Жилой и коммерческой недвижимости</li>
                        <li>Бизнеса, доли</li>
                        <li>Ценных бумаг</li>
                        <li>Ущерба</li>
                        <li>Транспорта, оборудования</li>
                        <li>Нематериальных активов</li>
                        <li>Оспаривание кадастровой стоимости</li>
                    </ul>
                </div>
                <div className="expertise">
                    <h1>Экспертиза</h1>
                    <ul>
                        <li>Оценочная</li>
                        <li>Строительно-техническая</li>
                        <li>Проектной документации</li>
                        <li>Автотехническая</li>
                        <li>Землеустроительная</li>
                        <li>Почерковедческая</li>
                        <li>Экономическая</li>
                    </ul>
                </div>
                <div>
                    <h1>Рецензирование</h1>
                    <ul>
                        <li>Проверка отчетов и экспертиз на соответствие Законам и Нормативным актам. </li>
                        <li>Изучение на предмет ошибок в расчетах и применяемых методиках. </li>
                        <li>Проверка исходных документов.</li>
                    </ul>
                </div>
            </div>
        </BasePage>
        );
    }
}

export default Jobs;