import React from 'react';
import BasePage from './../../utils/BasePage';
import './About.scss';


class About extends React.Component {
    render () {
        return (
        <BasePage className="about">
            <div style={{textAlign: "center"}} className="header">
                <h1>Коротко о нас</h1>
            </div>
            <div className="text">
                <ul>
                    <li>Cтраховой полис гражданской ответственности на 500 000 000 рублей</li>
                    <li>При необходимости подкрепляем свои выводы рецензией СРО</li>
                    <li>Работаем с 2004 года. Устоявшаяся, слаженная команда высококвалифицированных специалистов</li>
                    <li>Творческий и индивидуальный подход к каждому проекту</li>
                    <li>Тщательный анализ входящей информации. Видим и находим то, что не видят другие</li>
                    <li>Мы аккредитованы в ассоциации "Саморегулируемая организация арбитражных управляющих Центрального федерального округа" (ПАУ ЦФО)</li>
                    <li>Защита сделанных нами отчетов и экспертных заключений в суде</li>
                </ul>
            </div>
        </BasePage>
        );
    }
}

export default About;
