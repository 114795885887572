import React from 'react';
import BasePage from './../../utils/BasePage';
import './Clients.scss';


class Clients extends React.Component {
    render () {
        return (
        <BasePage className="clients">
            <div style={{textAlign: "center"}} className="header">
                <h1>Наши клиенты</h1>
            </div>
            <div style={{textAlign: "center"}} className="logos">
                <img src="clients/rolf.png" alt=""></img>
                <img src="clients/azimut.svg" alt=""></img>
                <img src="clients/avtodor.svg" alt=""></img>
            {/* </div>
            <div style={{textAlign: "center"}} className="logos"> */}
                <img src="clients/president2.png" alt=""></img>
                <img src="clients/ngba2.jpg" alt=""></img>
                <img src="clients/glincom.svg" alt=""></img>
            </div>
            <div className="more">
                А также:
                <ul>
                    <li>ООО «Южная региональная зерновая Компания»</li>
                    <li>АО "Морской грузовой терминал «Кавказ»</li>
                    <li>ЗАО «Компания информконтакт консалтинг»</li>
                    <li>ЗАО УК «Роснедвижимость»</li>
                    <li>ООО «Северная грузовая компания»</li>
                    <li>ООО «Фирма печатный двор»</li>
                </ul>
            </div>
        </BasePage>
        );
    }
}

export default Clients;