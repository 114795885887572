import React from 'react';
import "./BasePage.scss";


class BasePage extends React.Component {
    render() {
        return (
            <div className={"base-wrapper " + this.props.className}>
                <div style={this.props.style}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default BasePage;