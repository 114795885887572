import React from 'react';
import BasePage from '../../utils/BasePage';
import "./Main.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';


class Main extends React.Component {

    scrollTo (coef) {
        this.props.executeScroll(coef);
    }

    render () {
        return (
        <BasePage className="main">
            <div className="left">
                <h1 className="firm-name">ООО “Оценочное агентство”</h1>
                <div className="fast-contacts">
                    <div>+7 (495) 766-04-56</div>
                    <FontAwesomeIcon icon={faCircle} className="separator"/>
                    <div>oa77msk@gmail.com</div>
                </div>
            </div>
            <div className="links">
                <div onClick={() => {this.scrollTo(1);}}>О нас</div>
                <div onClick={() => {this.scrollTo(2);}}>Услуги</div>
                <div onClick={() => {this.scrollTo(3);}}>Клиенты</div>
                <div onClick={() => {this.scrollTo(4);}}>Контакты</div>
            </div>
            <div className="messengers">
                <div className="buttons">
                    <a href="https://signal.me/#p/79295030407" target="_blank" without rel="noreferrer">
                        <img src="signal-logo.png" alt=""></img>
                    </a>
                    <a href="https://t.me/OA77msk" target="_blank" without rel="noreferrer">
                        <FontAwesomeIcon icon={faTelegram} />
                    </a>
                    <a href="https://wa.me/79295030407" target="_blank" without rel="noreferrer">
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                </div>
                <text>+7 (929) 503-04-07</text>
            </div>
        </BasePage>
        );
    }
}

export default Main;